import React from "react";
import LocationImg from "../../images/location.svg";
import { Message } from "../../lib/getMessages";

class MapComponent extends React.Component {
  render() {
    return (
      <div className="map-container">
        <div className="location-box">
          <img src={LocationImg} alt="Location Icon" />
          <h2>
            <Message dictKey="map.message" />
          </h2>
          <p>
            Zopping.com, #409, 24th Main Rd, Parangi Palaya, Sector 2, HSR
            Layout, Bengaluru, Karnataka 560102
          </p>
          <a
            className="viewmap"
            href="https://www.google.co.in/maps/place/ZopSmart/@12.9087589,77.6490363,19z/data=!3m1!4b1!4m5!3m4!1s0x3bae153102e88e17:0xffb35b7893dc15e!8m2!3d12.9087618!4d77.6495272?hl=en"
            target="_blank"
            rel="noreferrer"
          >
            <Message dictKey="map.viewmap" />
          </a>
        </div>
      </div>
    );
  }
}
export default MapComponent;
