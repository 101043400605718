/* eslint-disable jsx-a11y/no-autofocus */
import React, { Component } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import chatImage from "../../images/chat.svg";
import customerServiceImage from "../../images/customer-service.webp";
import emailImage from "../../images/email.svg";
import phoneImage from "../../images/phone.svg";
import { Input, Message, Textarea } from "../../lib/getMessages";
import { Modal } from "../Hero";
import PhoneNumber from "../PhoneNumberUI";
import ResponseModal from "../ResponseModal";
import { CAPTCHA_SITE_KEY } from "../../utils/Constants";

const TAWK_TO_PROPERTY_ID = "61a5eef19099530957f747bc";
const TAWK_TO_WIDGET_ID = "1flo2qopb";
const emailPattern =
  /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
// TODO: Implement getMessage like function so as to pass translated text in props as well
const EMAIL_ERR_MSG = <Message dictKey={"email.error.msg"} />;
class ContactForm extends Component {
  constructor() {
    super();
    this.state = {
      name: "",
      phone: {
        dialCode: "+91",
        phoneNumber: "",
      },
      email: "",
      message: "",
      showSuccessPopup: false,
      validationError: false,
    };
  }

  handleCaptcha = (value) => {
    this.setState({
      captcha: value,
    });
  };

  submitData = (e) => {
    e.preventDefault();
    const formData = {};
    const formDataUsername = `${this.state.phone.dialCode}${this.state.phone.phoneNumber}`;
    formData.name = this.state.name;
    formData.email = this.state.email;
    formData.phone = formDataUsername;
    formData.message = this.state.message;
    formData["g-recaptcha-response"] = this.state.captcha;
    this.setState({
      status: "SUBMITTING",
    });
    let url = "https://staging.zopping.com/api/gateway-internal/enquiry";
    if (window.location.host === "zopping.com") {
      url = "https://zopping.com/api/gateway-internal/enquiry";
    }
    let headers = new Headers({
      "Content-Type": "application/json",
    });
    window
      .fetch(url, {
        method: "POST",
        headers,
        body: JSON.stringify(formData),
      })
      .then((response) => response.json())
      .then((response) => {
        if (response.code === 200 || response.code === 201) {
          this.setState({
            status: "SUBMITTED",
            name: "",
            phone: {
              dialCode: "+91",
              phoneNumber: "",
            },
            email: "",
            message: "",
            showSuccessPopup: true,
          });
        } else {
          this.setState({
            status: "FAILED",
          });
        }
      })
      .catch((e) => {
        this.setState({
          status: "FAILED",
        });
        console.error(e);
      });
  };

  handleChange = (event) => {
    if (
      (this.state.email && !emailPattern.test(this.state.email)) ||
      event.target.error
    ) {
      this.setState({
        [event.target.name]: event.target.value,
        validationError: true,
      });
    } else {
      this.setState({
        [event.target.name]: event.target.value,
        validationError: false,
      });
    }
  };
  handlePhoneChange = (phoneData) => {
    if (phoneData?.error) {
      this.setState({ validationError: true });
    }
    this.handleChange({
      target: {
        name: "phone",
        error: phoneData.error,
        value: phoneData,
      },
    });
  };

  closeModal = (event) => {
    this.setState({
      showSuccessPopup: false,
    });
    this.captcha.reset();
  };

  render() {
    const { ShowQuickResponse, isDirectionRTL } = this.props;
    const {
      captcha,
      submitting,
      phone,
      validationError,
      name,
      email,
      message,
    } = this.state;
    return (
      <main className="main-contact-container">
        <div className="container contact-us-page-wrp">
          <img
            className="contact-image"
            alt="customer-service"
            src={customerServiceImage}
          />
          <form
            className="contact-form"
            onSubmit={this.submitData}
            id="contact-form"
          >
            {this.state.status === "FAILED" && (
              <p className="error">
                Oops! Form submission failed. Please email your queries to
                <a href="mailto:support@zopping.com">support@zopping.com</a>
              </p>
            )}
            <div>
              <Input
                name="name"
                type="text"
                id="contact-form-name"
                className="contact-form-field"
                placeholderKey="contact.placeholder.fullname"
                value={this.state.name}
                onChange={this.handleChange}
                required
              />
              <PhoneNumber
                {...phone}
                handleAddPhoneData={this.handlePhoneChange}
                placeholder="Phone number"
                id="contact-form-mobile"
                isDirectionRTL={isDirectionRTL}
              />
              <Input
                name="email"
                type="email"
                id="contact-form-email"
                className="contact-form-field"
                placeholderKey="contact.placeholder.email"
                value={this.state.email}
                onChange={this.handleChange}
                title={EMAIL_ERR_MSG}
                required
              />
              {validationError && !emailPattern.test(email) && email && (
                <div
                  className={`signup-button-error ${
                    isDirectionRTL && "rtl-support"
                  }`}
                >
                  <Message dictKey={"email.error.msg"} />
                </div>
              )}
              <Textarea
                name="message"
                rows="3"
                id="contact-form-message"
                className="contact-form-field"
                placeholderKey="contact.placeholder.message"
                value={this.state.message}
                onChange={this.handleChange}
                required
              />
              <ReCAPTCHA
                sitekey={CAPTCHA_SITE_KEY}
                theme="light"
                className="captcha-contact-desktop"
                onChange={this.handleCaptcha}
                ref={(el) => {
                  this.captcha = el;
                }}
                onExpired={() => this.setState({ captcha: "" })}
              />
            </div>
            <div>
              <input
                disabled={
                  !captcha ||
                  submitting ||
                  validationError ||
                  !name ||
                  !email ||
                  !phone.phoneNumber ||
                  !message
                }
                type="submit"
                className="contact-form-submit"
                value={
                  this.state.status === "SUBMITTING"
                    ? "Submitting..."
                    : "TELL ME MORE"
                }
              />
            </div>
          </form>
          <Modal show={this.state.showSuccessPopup} onClose={this.closeModal}>
            <ResponseModal onClose={this.closeModal} />
          </Modal>
        </div>
        {ShowQuickResponse ? (
          <div>
            <h3>
              <Message dictKey="contact.reachout" />
            </h3>
            <h4>
              <Message dictKey="contact.reachout.question" />
            </h4>
            <hr></hr>
            <div className="contact-container">
              <a href="tel:+919916814808" className="contact-icon">
                <img src={phoneImage} alt="contact-number" />
                <p>
                  +91 99168 14808
                  <span>+44 74183 10190</span>
                  <span>+1 208 913 3258</span>
                </p>
              </a>
              <a className="contact-icon" href="mailto:support@zopping.com">
                <img src={emailImage} alt="email" />
                <p>support@zopping.com</p>
              </a>
              <a
                className="contact-icon"
                href={`https://tawk.to/chat/${TAWK_TO_PROPERTY_ID}/${TAWK_TO_WIDGET_ID}`}
                target="popup"
                onClick={() =>
                  window.open(
                    `https://tawk.to/chat/${TAWK_TO_PROPERTY_ID}/${TAWK_TO_WIDGET_ID}`,
                    "_blank",
                    `width=400 height=480`
                  )
                }
              >
                <img src={chatImage} alt="chat-with-us" />
                <p>
                  <Message dictKey="contact.chat" />
                </p>
              </a>
            </div>
          </div>
        ) : null}
      </main>
    );
  }
}

export default ContactForm;
