import React, { Component } from "react";
import { Helmet } from "react-helmet";
import ContactForm from "../../components/ContactForm";
import EazyHeader from "../../components/Header";
import LayoutWrapper from "../../components/Layout";
import MapComponent from "../../components/MapComponent";
import WithProviders from "../../components/WithProviders";
import { Message } from "../../lib/getMessages";
import "./contact.css";

class Contact extends Component {
  render() {
    const { language, updateLanguage, direction } = this.props;
    let canonicalUrl = `https://staging.zopping.com/contact`;
    if (typeof window !== "undefined") {
      if (window.location.host === "zopping.com") {
        canonicalUrl = `https://zopping.com/contact`;
      }
    }
    const isDirectionRTL = direction === "rtl";

    return (
      <LayoutWrapper language={language} location={this.props.location}>
        <div className="eazy-page">
          <Helmet title="Zopping - Contact us">
            <meta
              name="description"
              content="Reach us | Email: support@zopping.com | Phone: IN: +91 99168 14808, UK: +44 74183 10190, USA: +1 858 207 6278
"
            />
            <link rel="canonical" href={canonicalUrl} />
          </Helmet>
          <EazyHeader
            language={language}
            updateLanguage={updateLanguage}
            current="contact"
            isDirectionRTL={isDirectionRTL}
          />
          <div className="eazy-contact">
            <section className="overview tc">
              <section className="brief">
                <div>
                  <p>
                    <Message dictKey="contact.help.banner.question" />
                  </p>
                  <p>
                    <Message dictKey="contact.help.banner.tagline" />
                  </p>
                </div>
              </section>
            </section>
          </div>
          <ContactForm
            ShowQuickResponse={true}
            isDirectionRTL={isDirectionRTL}
          />
          <MapComponent />
        </div>
      </LayoutWrapper>
    );
  }
}

export default WithProviders(Contact);
